export const getUSStates = () => {
    return [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'American Samoa', abbreviation: 'AS' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'District of Columbia', abbreviation: 'DC' },
        { name: 'States of Micronesia', abbreviation: 'FM' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Guam', abbreviation: 'GU' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Marshall Islands', abbreviation: 'MH' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Northern Mariana Islands', abbreviation: 'MP' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Palau', abbreviation: 'PW' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Puerto Rico', abbreviation: 'PR' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virgin Islands', abbreviation: 'VI' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' }
    ];
};

export const getCanadaProvinces = () => {
    return [
        { name: 'Alberta', abbreviation: 'AB', hst: 0.05 },
        { name: 'British Columbia', abbreviation: 'BC', hst: 0.12 },
        { name: 'Manitoba', abbreviation: 'MB', hst: 0.12 },
        { name: 'New Brunswick', abbreviation: 'NB', hst: 0.15 },
        { name: 'Newfoundland and Labrador', abbreviation: 'NL', hst: 0.15 },
        { name: 'Northwest Territories', abbreviation: 'NT', hst: 0.05 },
        { name: 'Nova Scotia', abbreviation: 'NS', hst: 0.15 },
        { name: 'Nunavut', abbreviation: 'NU', hst: 0.05 },
        { name: 'Ontario', abbreviation: 'ON', hst: 0.13 },
        { name: 'Prince Edward Island', abbreviation: 'PE', hst: 0.15 },
        { name: 'Quebec', abbreviation: 'QC', hst: 0.14975 },
        { name: 'Saskatchewan', abbreviation: 'SK', hst: 0.11 },
        { name: 'Yukon Territory', abbreviation: 'YT', hst: 0.05 }
    ];
};

export const getProductCategoryBaseRoutes = () => {
    return {
        'Currency': 'currency',
        'Items': 'items',
        'Accounts': 'accounts',
        'RS Questing': 'services',
        'Services': 'services',
        'RS Diaries': 'services',
        'RS PVM': 'services',
        'RS Minigames': 'services'
    };
};

export const emailDisallowConRegExr = () => new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?!con$)[a-zA-Z]{2,}/);

export const chargebackArrayToFilter = () => ['crypto', 'coinpayments', 'btcpay', 'bitcart'];

export const automaticPaymentMethods = () => ['bluesnap', 'paysafe', 'checkout', 'apple-pay-direct', 'google-pay-direct', 'solidgate', 'nmi', 'paymentlync', 'stripe'];

export const automaticPaymentMethodsWithout = () => ['bluesnap-checkout', 'google-pay-direct', 'apple-pay-direct'];

export const automaticPaymentMethodsWithout3DS = () => ['paymentlync', 'solidgate', 'google-pay-direct', 'apple-pay-direct'];

export const cardTypesFor3DSTrigger = () => ['visa', 'mastercard', 'amex', 'discover'];

export const htmlTagRegex = () => /<[^>]*>/g;

export const DesktopBreakpoint = 992;

export const PAGE_NAMES = {
    HOME: 'Home',
    CURRENCY: 'Currency',
    CONTACT: 'Contact Us',
    BLOG: 'Blog',
    FAQ: 'Faq',
    ABOUT: 'About Us',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_OF_SERVICE: 'Terms of Service',
    COPYRIGHT_POLICY: 'Copyright Policy',
    CART: 'Cart',
    CUSTOMER_PORTAL: 'Customer Portal',
    EXPIRED_LINK: 'Expired Link',
    ORDER_DETAILS: 'Order Details',
    BUG_BOUNTY: 'Bug Bounty',
    SITEMAP: 'Sitemap',
    SUBSCRIPTION: 'Subscription',
    BALANCE: 'Balance'
};

export default this;
