export class ValidationRenderer {
    stopWatch;

    render(instruction) {
        for (const { result, elements } of instruction.unrender) {
            for (const element of elements) {
                this.remove(element, result);
            }
        }

        for (const { result, elements } of instruction.render) {
            for (const element of elements) {
                this.add(element, result);
            }
        }
    }

    add(element, result) {
        clearTimeout(this.stopWatch);

        if (result.valid) {
            return;
        }

        switch (result.propertyName) {
            case 'newEmail':
            case 'newPassword':
            case 'confirmNewPassword':
            case 'resetPassword':
            case 'confirmPassword':
            case 'recoveryEmail':
            case 'email':
            case 'password':
            case 'token':
            case 'slowValidateEmail':
            case 'street':
            case 'city':
            case 'cvv':
                return;
        }

        element.classList.add('is-invalid');

        const customElement =
            element.closest('.mdc-text-field') ||
            element.closest('.mdc-select');
        if (!customElement) {
            return;
        }

        if (
            result.propertyName === 'slowValidateEmail' &&
            element.isFocused === true
        ) {
            this.stopWatch = setTimeout(() => {
                const messageExists = document.querySelector(`#validation-message-${result.id}`);
                if (!messageExists) {
                    const message = document.createElement('div');
                    message.className = 'invalid-feedback mb-1';
                    message.textContent = result.message;
                    message.id = `validation-message-${result.id}`;
                    customElement.after(message);
                    customElement.classList.remove('mb-1');
                }
            }, 2000);
        } else {
            const messageExists = document.querySelector(`#validation-message-${result.id}`);
            if (!messageExists) {
                const message = document.createElement('div');
                message.className = 'invalid-feedback mb-1';
                message.textContent = result.message;
                message.id = `validation-message-${result.id}`;
                customElement.after(message);
                customElement.classList.remove('mb-1');
            }
        }
    }

    remove(element, result) {
        if (result.valid) {
            return;
        }

        switch (result.propertyName) {
            case 'newEmail':
            case 'newPassword':
            case 'confirmNewPassword':
            case 'resetPassword':
            case 'confirmPassword':
            case 'recoveryEmail':
            case 'email':
            case 'password':
            case 'token':
            case 'slowValidateEmail':
            case 'street':
            case 'city':
            case 'cvv':
                return;
        }

        const customElement =
            element.closest('.mdc-text-field') ||
            element.closest('.mdc-select');
        if (!customElement) {
            return;
        }

        const message = document.querySelector(
            `#validation-message-${result.id}`
        );
        const invalidClass = document.querySelectorAll('invalid-feedback mb-1');
        if (message) {
            message.remove();
            if (invalidClass.length === 0) {
                customElement.classList.remove('is-invalid');
            }
        }
    }
}
