import './checkout-form.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { CurrencyService } from 'services/currency-service';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { checkoutPublicKey, debug } from 'environment';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { Card } from 'services/models/purchase-flow/card';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class CheckoutForm {
    constructor(
        private router: Router,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private currencyService: CurrencyService,
        private validationController: ValidationController,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private toastService: ToastService,
        private helper: Helper) {
        this.validator = validationController;
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.router = router;
    }

    bind(bindingContext) {
        this.parent = bindingContext;
        this.parent.summaryButtonState = 'disabled';
        this.parent.summaryButtonText = 'Pay now';
        this.parent.summaryButtonFunction = this.authorizeCharge.bind(this);
    }

    @bindable firstNameOnCard;
    @bindable lastNameOnCard;
    @bindable billing;
    @bindable preferredCurrency;
    @bindable forceCad;
    @bindable totalPrice;
    @bindable paymentToken;
    @bindable loading;
    @bindable usingPreviousCard = false;
    @bindable selectedPaymentMethod;
    @bindable showBillingGreenCheckMark;
    @bindable showBillingErrorCheckMark;
    @bindable emptySavedCards = false;
    @bindable isBalance = false;
    @observable selectedCard: Card = {};

    availableCards = [];
    fieldsLoaded = false;
    cardSource = '/payment-methods/generic.svg';
    cardHeight = 20;
    readyingForm;
    currentYear;
    currentMonth;
    showGreenCheckMarkFirstNameExpiry;
    showErrorCheckMarkFirstNameExpiry;
    showGreenCheckMarkLastNameExpiry;
    showErrorCheckMarkLastNameExpiry;
    showGreenCheckMarkDateExpiry;
    showErrorCheckMarkDateExpiry;
    firstNameStopWatch;
    firstNameStopWatch2;
    lastNameStopWatch;
    lastNameStopWatch2;
    showGreenInputFirstNameOnCard;
    showGreenInputLastNameOnCard;
    cadRate;
    updatingCardExpiry;
    firstNameExpiry;
    lastNameExpiry;
    showCcnError;
    showCreditCardType;
    showExpError;
    showCvvError;
    showErrorInputFirstNameOnCard;
    showErrorInputLastNameOnCard;
    timeouts;
    cardNumberTextField;
    cvvTextField;
    expiryDateTextField;
    ignore3DS;
    cardExpiryDate;
    checkoutVaultedProfile;
    replaceValue;
    expiryDate;
    fieldsArray : HTMLElement[];
    validator;
    parent;
    customer;
    elementFocused;
    deviceSessionId;
    remainingRequests;

    attached() {
        this.helper.initializeRateLimitingForAutomaticMethods();
        this.checkoutVaultedProfile?.instruments?.length > 0 ? this.usingPreviousCard = true : this.usingPreviousCard = false;
        this.usingPreviousCard ? this.parent.shouldShowBlueLine = true : this.parent.shouldShowBlueLine = false;
        this.usingPreviousCard ? this.parent.summaryButtonState = 'active' : this.parent.summaryButtonState = 'disabled';
        this.fieldsLoaded && !this.usingPreviousCard ? this.initializeCreditCardFields() : '';
        this.emptySavedCards = false;
    }

    async created() {
        try {
            this.readyingForm = true;
            this.customer = await this.sessionService.getProfile();
            this.checkoutVaultedProfile = await this.customerService.getCheckoutVaultProfile();
            const date = new Date;
            this.currentYear = parseInt(date.getFullYear().toString().substring(2));
            this.currentMonth = date.getMonth() + 1;
            if (this.checkoutVaultedProfile?.instruments?.length > 0) {
                this.usingPreviousCard = true;
                for (const paymentSource of this.checkoutVaultedProfile?.instruments || 0) {
                    paymentSource.expiryMonth.toString().length < 2 ? paymentSource.expiryMonth = '0' + paymentSource.expiryMonth.toString() : paymentSource.expiryMonth;
                    paymentSource.expiryYear = paymentSource.expiryYear.toString().substring(2);
                    this.availableCards.push(paymentSource);
                }
                if (this.availableCards?.length > 0) {
                    const checkoutVaultLastUsedCard = await this.customerService.getCheckoutVaultLastUsedCard();
                    if (checkoutVaultLastUsedCard) {
                        this.selectedCard = this.availableCards.find(c => checkoutVaultLastUsedCard.source?.last4 === c.last4 &&
                            checkoutVaultLastUsedCard.source?.sheme?.toLowerCase() === c.scheme.toLowerCase());
                        if (!this.selectedCard) {
                            this.selectedCard = this.availableCards[0];
                        }
                    } else {
                        this.selectedCard = this.availableCards[0];
                    }
                }
                this.parent.summaryButtonState = 'active';
                this.parent.shouldShowBlueLine = true;
                this.setBillingForCard();
            } else {
                this.billing.zip = this.customer?.zip;
                this.billing.street = this.customer?.address;
                this.billing.country = this.customer?.country?.toUpperCase();
                this.billing.state = this.customer?.state;
                this.billing.city = this.customer?.city;
            }

            this.firstNameOnCard = this.customer?.firstName;
            this.lastNameOnCard = this.customer?.lastName;
            this.firstNameOnCard ? this.showGreenInputFirstNameOnCard = true : '';
            this.lastNameOnCard ? this.showGreenInputLastNameOnCard = true : '';

            if (!this.usingPreviousCard) {
                this.parent.shouldShowBlueLine = false;
                this.initializeCreditCardFields();
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.readyingForm = false;
            this.loading = false;
        }
    }

    detached() {
        const script = document.getElementById('checkout-script');
        if (script) {
            script.remove();
        }
    }

    setBillingForCard() {
        if (this.selectedCard?.accountHolder?.billingAddress) {
            const foundAddress = this.selectedCard?.accountHolder.billingAddress;
            if (foundAddress) {
                this.billing = {
                    zip: foundAddress.zip,
                    street: foundAddress.addressLine1,
                    country: foundAddress.country,
                    state: foundAddress.state,
                    city: foundAddress.city
                };
            } else {
                this.billing = {};
            }
        }
    }

    selectedCardChanged() {
        this.setBillingForCard();
        if (this.updatingCardExpiry) {
            this.firstNameExpiry = this.selectedCard.name ? this.selectedCard.name.split(' ')[0] : this.firstNameOnCard;
            this.lastNameExpiry = this.selectedCard.name ? this.selectedCard.name.split(' ')[1] : this.lastNameOnCard;
            this.showGreenCheckMarkFirstNameExpiry = true;
            this.showGreenCheckMarkLastNameExpiry = true;
        }
    }

    updateExpiryOnClick() {
        this.updatingCardExpiry = !this.updatingCardExpiry;
        if (this.updatingCardExpiry) {
            this.firstNameExpiry = this.selectedCard.name ? this.selectedCard.name.split(' ')[0] : this.firstNameOnCard;
            this.lastNameExpiry = this.selectedCard.name ? this.selectedCard.name.split(' ')[1] : this.lastNameOnCard;
            this.showGreenCheckMarkFirstNameExpiry = true;
            this.showGreenCheckMarkLastNameExpiry = true;
        }
    }

    async initializeCreditCardFields() {
        this.readyingForm = true;
        if (this.fieldsLoaded) {
            const cardNumberEl = document.getElementById('card-number');
            cardNumberEl.removeChild(cardNumberEl.firstChild);
            cardNumberEl.classList.remove('ds-input--success');
            cardNumberEl.classList.remove('ds-input--error');
            const expiryDateEl = document.getElementById('expiry-date');
            expiryDateEl.removeChild(expiryDateEl.firstChild);
            expiryDateEl.classList.remove('ds-input--success');
            expiryDateEl.classList.remove('ds-input--error');
            const cvvEl = document.getElementById('cvv');
            cvvEl.removeChild(cvvEl.firstChild);
            cvvEl.classList.remove('ds-input--success');
            cvvEl.classList.remove('ds-input--error');
            this.showCcnError = this.showExpError = this.showCvvError = false;
            this.cardSource = '/payment-methods/generic.svg';
        }
        window.Frames.init({
            publicKey: checkoutPublicKey(),
            acceptedPaymentMethods: [
                'Visa',
                'Maestro',
                'Mastercard',
                'American Express',
                'Diners Club',
                'Discover',
                'JCB',
                'Mada'
            ],
            localization: {
                cardNumberPlaceholder: 'Card Number',
                expiryMonthPlaceholder: 'MM ',
                expiryYearPlaceholder: ' YY',
                cvvPlaceholder: 'CVV'
            },
            style: {
                base: {
                    color: 'rgba(59, 60, 81, 0.75)',
                    fontSize: '16px',
                    fontFamily: 'Roboto, sans-serif',
                    letterSpacing: '.009375em',
                    fontWeight: '400'
                },
                autofill: {
                    boxShadow: 'inset 0 0 0 1000px rgba(243, 245, 247, 0.911)'
                },
                invalid: {
                    color: 'rgba(59, 60, 81, 0.75)'
                },
                valid: {
                    color: 'rgba(59, 60, 81, 0.75)'
                },
                placeholder: {
                    base: {
                        fontFamily: 'Robot, sans-serif',
                        color: 'rgba(59, 60, 81, 0.75) !important'
                    }
                }
            },
            cardValidationChanged: () => {
                if (!window.Frames.isCardValid()) {
                    this.parent.summaryButtonState = 'disabled';
                }
            },
            cardTokenizationFailed: (event) => {
                this.toastService.showToast('Error', event, 'error');
            },
            frameValidationChanged: (event) => {
                if (this.elementFocused.id === event.element) {
                    if (event?.isValid) {
                        this.elementFocused.classList.remove('ds-input--error');
                        this.elementFocused.classList.add('ds-input--success');
                        switch (this.elementFocused.id) {
                            case 'card-number':
                                this.showCcnError = false;
                                break;
                            case 'expiry-date':
                                this.showExpError = false;
                                break;
                            case 'cvv':
                                this.showCvvError = false;
                                break;
                        }
                    } else {
                        this.elementFocused.classList.remove('ds-input--success');
                        this.elementFocused.classList.add('ds-input--error');
                        switch (this.elementFocused.id) {
                            case 'card-number':
                                this.showCcnError = true;
                                break;
                            case 'expiry-date':
                                this.showExpError = true;
                                break;
                            case 'cvv':
                                this.showCvvError = true;
                                break;
                        }
                    }
                }
                this.customerFullNameStyleChange();
            },
            frameFocus: (event) => {
                const element = document.getElementById(event.element);
                this.elementFocused = element;
                if (element) {
                    element.classList.remove('ds-input--success', 'ds-input--error');
                    switch (event.element) {
                        case 'card-number':
                            this.showCcnError = false;
                            break;
                        case 'expiry-date':
                            this.showExpError = false;
                            break;
                        case 'cvv':
                            this.showCvvError = false;
                            break;
                    }
                }
            },
            frameBlur: (event) => {
                const element = document.getElementById(event.element);
                if (element) {
                    if (element.classList.contains('frame--valid')) {
                        element.classList.add('ds-input--success');
                        switch (event.element) {
                            case 'card-number':
                                this.showCcnError = false;
                                break;
                            case 'expiry-date':
                                this.showExpError = false;
                                break;
                            case 'cvv':
                                this.showCvvError = false;
                                break;
                        }
                    } else {
                        element.classList.add('ds-input--error');
                        switch (event.element) {
                            case 'card-number':
                                this.showCcnError = true;
                                break;
                            case 'expiry-date':
                                this.showExpError = true;
                                break;
                            case 'cvv':
                                this.showCvvError = true;
                                break;
                        }
                    }
                }
                this.customerFullNameStyleChange();
            },
            paymentMethodChanged: (event) => {
                this.cardBrandRecognition(event?.paymentMethod);
            }
        });
        this.fieldsLoaded = true;
        this.parent.summaryButtonState = 'disabled';
        setTimeout(() => {
            this.readyingForm = false;
        }, 2000);
    }

    useNewCard() {
        this.parent.shouldShowBlueLine = false;
        this.usingPreviousCard = false;
        this.validator.reset();
        this.showErrorInputFirstNameOnCard = this.showErrorInputLastNameOnCard = null;
        this.initializeCreditCardFields();
    }

    usePreviousCard() {
        this.parent.shouldShowBlueLine = true;
        this.usingPreviousCard = true;
        this.parent.summaryButtonState = 'active';
        this.emptySavedCards = false;
        this.setBillingForCard();
    }

    async handleUsingPreviousCardIfExisting() {
        this.availableCards = [];
        this.checkoutVaultedProfile = await this.customerService.getCheckoutVaultProfile();
        if (this.checkoutVaultedProfile?.instruments?.length > 0) {
            this.usingPreviousCard = true;
            for (const paymentSource of this.checkoutVaultedProfile?.instruments || 0) {
                paymentSource.expiryMonth.toString().length < 2 ? paymentSource.expiryMonth = '0' + paymentSource.expiryMonth.toString() : paymentSource.expiryMonth;
                paymentSource.expiryYear = paymentSource.expiryYear.toString().substring(2);
                this.availableCards.push(paymentSource);
            }
            if (this.availableCards?.length > 0) {
                const checkoutVaultLastUsedCard = await this.customerService.getCheckoutVaultLastUsedCard();
                if (checkoutVaultLastUsedCard) {
                    this.selectedCard = this.availableCards.find(c => checkoutVaultLastUsedCard.source?.last4 === c.last4 &&
                        checkoutVaultLastUsedCard.source?.scheme?.toLowerCase() === c.scheme.toLowerCase());
                    if (!this.selectedCard) {
                        this.selectedCard = this.availableCards[0];
                    }
                } else {
                    this.selectedCard = this.availableCards[0];
                }
            }
            this.setBillingForCard();
            this.parent.shouldShowBlueLine = true;
            this.parent.summaryButtonState = 'active';
        } else {
            this.billing.zip = this.customer?.zip;
            this.billing.street = this.customer?.address;
            this.billing.country = this.customer?.country?.toUpperCase();
            this.billing.state = this.customer?.state;
            this.billing.city = this.customer?.city;
        }
    }

    isCardExpiringSoon(card) {
        if ((parseInt(card.expiryMonth) - this.currentMonth) <= 2 && parseInt(card.expiryMonth) >= this.currentMonth && this.currentYear === parseInt(card.expiryYear)) {
            return true;
        }
        return false;
    }

    isCardExpired(card) {
        if (parseInt(card.expiryYear) < this.currentYear || (parseInt(card.expiryMonth) < this.currentMonth && this.currentYear === parseInt(card.expiryYear))) {
            return true;
        }
        return false;
    }

    async firstNameOnCardOnKeyPress() {
        this.showGreenInputFirstNameOnCard = this.showErrorInputFirstNameOnCard = false;
        this.timeouts = [this.firstNameStopWatch, this.firstNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
            this.validator.validate();
        }
        this.firstNameStopWatch = setTimeout(async() => {
            if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                ValidationRules
                    .ensure('firstNameOnCard').required().withMessage('First name required.')
                    .ensure('lastNameOnCard').required().withMessage('Last name required.')
                    .on(this);
            } else {
                ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
            }
            const rules = await this.validator.validate();
            this.showGreenInputFirstNameOnCard = this.validatorCheckOneCondition('firstNameOnCard', rules.results);
            if (!this.showGreenInputFirstNameOnCard) {
                await this.validator.reset();
                if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                    ValidationRules
                        .ensure('lastNameOnCard').required().withMessage('Last name required.')
                        .on(this);
                    this.validator.validate();
                }
                this.firstNameStopWatch2 = setTimeout(async() => {
                    if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                        ValidationRules
                            .ensure('firstNameOnCard').required().withMessage('First name required.')
                            .ensure('lastNameOnCard').required().withMessage('Last name required.')
                            .on(this);
                    } else {
                        ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
                    }
                    await this.validator.validate();
                    this.showErrorInputFirstNameOnCard = !this.validatorCheckOneCondition('firstNameOnCard', rules.results);
                }, 2000);
            }
            if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                this.showGreenInputLastNameOnCard = this.validatorCheckOneCondition('lastNameOnCard', rules.results);
                this.showErrorInputLastNameOnCard = !this.validatorCheckOneCondition('lastNameOnCard', rules.results);
            }
        }, 2000);
    }

    firstNameOnFocusIn() {
        this.showGreenInputFirstNameOnCard = this.showErrorInputFirstNameOnCard = false;
        this.validator.reset();
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
            this.validator.validate();
        }
    }

    async checkFirstNameValidation() {
        this.timeouts = [this.firstNameStopWatch, this.firstNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
        } else {
            ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
        }
        const rules = await this.validator.validate();
        this.showGreenInputFirstNameOnCard = this.validatorCheckOneCondition('firstNameOnCard', rules.results);
        this.showErrorInputFirstNameOnCard = !this.validatorCheckOneCondition('firstNameOnCard', rules.results);
        this.customerFullNameStyleChange();
    }

    async lastNameOnCardOnKeyPress() {
        this.showGreenInputLastNameOnCard = this.showErrorInputLastNameOnCard = false;
        this.timeouts = [this.lastNameStopWatch, this.lastNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
            this.validator.validate();
        }
        this.lastNameStopWatch = setTimeout(async() => {
            if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                ValidationRules
                    .ensure('lastNameOnCard').required().withMessage('Last name required.')
                    .ensure('firstNameOnCard').required().withMessage('First name required.')
                    .on(this);
            } else {
                ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
            }
            const rules = await this.validator.validate();
            this.showGreenInputLastNameOnCard = this.validatorCheckOneCondition('lastNameOnCard', rules.results);
            if (!this.showGreenInputLastNameOnCard) {
                await this.validator.reset();
                if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                    ValidationRules
                        .ensure('firstNameOnCard').required().withMessage('First name required.')
                        .on(this);
                    this.validator.validate();
                }
                this.lastNameStopWatch2 = setTimeout(async() => {
                    if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                        ValidationRules
                            .ensure('lastNameOnCard').required().withMessage('Last name required.')
                            .ensure('firstNameOnCard').required().withMessage('First name required.')
                            .on(this);
                    } else {
                        ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
                    }
                    const rules2 = await this.validator.validate();
                    this.showErrorInputLastNameOnCard = !this.validatorCheckOneCondition('lastNameOnCard', rules2.results);
                }, 2000);
            }
            if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                this.showGreenInputFirstNameOnCard = this.validatorCheckOneCondition('firstNameOnCard', rules.results);
                this.showErrorInputFirstNameOnCard = !this.validatorCheckOneCondition('firstNameOnCard', rules.results);
            }
        }, 2000);
    }

    lastNameOnFocusIn() {
        this.showGreenInputLastNameOnCard = this.showErrorInputLastNameOnCard = false;
        this.validator.reset();
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
            this.validator.validate();
        }
    }

    async checkLastNameValidation() {
        this.timeouts = [this.lastNameStopWatch, this.lastNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
        } else {
            ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
        }
        const rules = await this.validator.validate();
        this.showGreenInputLastNameOnCard = this.validatorCheckOneCondition('lastNameOnCard', rules.results);
        this.showErrorInputLastNameOnCard = !this.validatorCheckOneCondition('lastNameOnCard', rules.results);
        this.customerFullNameStyleChange();
    }

    validatorCheckOneCondition(field, results) {
        let valid = true;
        for (const result of results) {
            if (result.propertyName === field && !result.valid) {
                valid = false;
            }
        }
        return valid;
    }

    async authorizeCharge() {
        try {
            if (await this.helper.reduceRateLimitTokenForAutomaticMethods() < 0 && !debug()) {
                return this.toastService.showToast('Error', 'You’ve made a payment attempt too many times recently. Try again later.', 'error');
            }

            if (this.billing.country === 'CA' || this.billing.country === 'US') {
                if (!this.billing.country || !this.billing.state || !this.billing.city || !this.billing.street || !this.billing.zip) {
                    return this.toastService.showToast('Invalid billing address!', 'Some required information is missing or incorrect. Please correct the address fields and try again.', 'error');
                }
            } else {
                if (!this.billing.country || !this.billing.city || !this.billing.street || !this.billing.zip) {
                    return this.toastService.showToast('Invalid billing address!', 'Some required information is missing or incorrect. Please correct the address fields and try again.', 'error');
                }
            }

            if (!this.usingPreviousCard) {
                if (!this.fieldsLoaded) {
                    return;
                }
                ValidationRules
                    .ensure('firstNameOnCard').required().withMessage('First name required.')
                    .ensure('lastNameOnCard').required().withMessage('Last name required.')
                    .on(this);
                const rules = await this.validator.validate();
                if (!rules.valid) {
                    if (this.firstNameOnCard) {
                        this.showGreenInputFirstNameOnCard = true;
                        this.showErrorInputFirstNameOnCard = !this.showGreenInputFirstNameOnCard;
                    } else {
                        this.showErrorInputFirstNameOnCard = true;
                        this.showGreenInputFirstNameOnCard = !this.showErrorInputFirstNameOnCard;
                    }
                    if (this.lastNameOnCard) {
                        this.showGreenInputLastNameOnCard = true;
                        this.showErrorInputLastNameOnCard = !this.showGreenInputLastNameOnCard;
                    } else {
                        this.showErrorInputLastNameOnCard = true;
                        this.showGreenInputLastNameOnCard = !this.showErrorInputLastNameOnCard;
                    }
                }
            }

            if (!this.usingPreviousCard && (!this.firstNameOnCard || !this.lastNameOnCard || this.showCcnError || this.showCvvError || this.showExpError)) {
                this.parent.summaryButtonState = 'disabled';
                return this.toastService.showToast('Invalid payment details!', 'Some required information is missing or incorrect. Please correct the payment details fields and try again.', 'error');
            }

            this.parent.summaryButtonState = 'processing';

            try {
                this.deviceSessionId = await this.handleCheckoutFraudDetection();
            } catch (e) {
                console.log(e);
            }

            if (this.usingPreviousCard && this.selectedCard) {
                this.handleUsingSelectedCard();
                return;
            } else if (!this.selectedCard && this.usingPreviousCard) {
                this.toastService.showToast('Error', 'Please select a credit card to proceed with your payment.', 'error');
                this.parent.summaryButtonState = 'error';
                this.loading = false;
            } else {
                window.Frames.enableSubmitForm();

                window.Frames.cardHolder = window.Frames.cardholder = window.Frames.currentConfig.cardholder = window.Frames.currentConfig.cardHolder = {
                    name: `${this.firstNameOnCard} ${this.lastNameOnCard}`,
                    billingAddress: {
                        addressLine1: this.billing.street,
                        zip: this.billing.zip,
                        city: this.billing.city,
                        state: this.billing.state,
                        country: this.billing.country
                    },
                    phone: this.customer.phoneCountryCode + this.customer.phoneNumber
                };

                window.Frames.submitCard()
                    .then((data) => {
                        this.parent.handleCheckoutOrder(data.token, data, this.deviceSessionId);
                    })
                    .catch((e) => {
                        console.log(e);
                        this.toastService.showToast('Error', `${e}}`, 'error');
                        this.parent.summaryButtonState = 'error';
                        this.loading = false;
                    });
            }
        } catch (e) {
            console.log(e);
            this.parent.summaryButtonState = 'error';
            this.loading = false;
        }
    }

    cardBrandRecognition(cardBrand) {
        switch (cardBrand) {
            case 'American Express':
                this.cardSource = '/payment-methods/amex.svg';
                break;
            case 'Mastercard':
                this.cardSource = '/payment-methods/mastercard.svg';
                break;
            case 'Visa':
                this.cardSource = '/payment-methods/visa.svg';
                break;
            case 'Diners Club':
                this.cardSource = '/payment-methods/diners.svg';
                break;
            case 'Discover':
                this.cardSource = '/payment-methods/discover.svg';
                break;
            case 'Maestro':
                this.cardSource = '/payment-methods/maestro.svg';
                break;
            case 'JCB':
                this.cardSource = '/payment-methods/jcb.svg';
                break;
            case 'Mada':
                this.cardSource = '/payment-methods/mada.svg';
                break;
        }
    }

    showBillingGreenCheckMarkChanged() {
        this.customerFullNameStyleChange();
    }

    customerFullNameStyleChange() {
        if (!this.usingPreviousCard) {
            if (window.Frames.isCardValid() && this.firstNameOnCard && this.lastNameOnCard && this.billing.city && this.billing.country && this.billing.street && this.billing.zip && this.showBillingGreenCheckMark) {
                this.parent.summaryButtonState = 'active';
            } else {
                this.parent.summaryButtonState = 'disabled';
            }
        } else {
            this.parent.summaryButtonState = 'active';
        }
    }

    getCardImage(type) {
        switch (type.toLowerCase()) {
            case 'visa':
                return '/payment-methods/visa.svg';
            case 'mastercard':
                return '/payment-methods/mastercard.svg';
            case 'american express':
            case 'amex':
                return '/payment-methods/amex.svg';
            case 'discover':
                return '/payment-methods/discover.svg';
            case 'diners club':
                return '/payment-methods/diners.svg';
            case 'maestro':
                return '/payment-methods/maestro.svg';
            case 'jcb':
                return '/payment-methods/jcb.svg';
            case 'mada':
                return '/payment-methods/mada.svg';
            default:
                return '/payment-methods/generic.svg';
        }
    }

    savedCardStyling(type) {
        switch (type.toLowerCase()) {
            case 'visa':
            case 'mastercard':
            case 'american express':
            case 'amex':
            case 'discover':
            case 'diners club':
            case 'maestro':
            case 'jcb':
            case 'mada':
                return true;
            default:
                return false;
        }
    }

    handleUsingSelectedCard() {
        if (this.isCardExpired(this.selectedCard)) {
            this.toastService.showToast('Card Expired!', 'Please update your card expiration.', 'error');
            this.parent.summaryButtonState = 'error';
            this.loading = false;
        } else {
            this.parent.handleCheckoutOrder(null, this.selectedCard, this.deviceSessionId);
        }
    }

    async removeCreditCard(card) {
        if (window.confirm('Are you sure you would like to remove this card?')) {
            const response = await this.customerService.deleteCheckoutCard(card.id);
            if (response) {
                const cardIndex = this.availableCards.findIndex(x => x.id === card.id);
                if (cardIndex > -1) {
                    this.availableCards.splice(cardIndex, 1);
                }
                if (this.availableCards?.length === 0) {
                    this.parent.shouldShowBlueLine = false;
                    this.usingPreviousCard = false;
                    this.showBillingGreenCheckMark = false;
                    this.showBillingErrorCheckMark = false;
                    this.emptySavedCards = true;
                    this.billing.zip = this.customer?.zip;
                    this.billing.street = this.customer?.address;
                    this.billing.country = this.customer?.country?.toUpperCase();
                    this.billing.state = this.customer?.state;
                    this.billing.city = this.customer?.city;
                    this.initializeCreditCardFields();
                } else {
                    this.selectedCard = this.availableCards[0];
                }
                return this.toastService.showToast('Success!', 'Card removed.', 'success');
            }
        }
    }

    getCardAddress(card) {
        const billingInfo = card.accountHolder.billingAddress;
        if (billingInfo) {
            return `${billingInfo.addressLine1}, ${billingInfo.city}, ${billingInfo.state !== null ? billingInfo.state + ',' : ''} ${billingInfo.zip}, ${billingInfo.country.toUpperCase()}`;
        }
    }

    async updateCardExpiry() {
        try {
            ValidationRules
                .ensure('firstNameExpiry').required().withMessage('First name required.')
                .ensure('lastNameExpiry').required().withMessage('Last name required.')
                .ensure('expiryDate').required().matches(/^(0[1-9]|1[0-2]) \/ ([0-9]{2})$/)
                .satisfies((dateExp) => {
                    if (dateExp?.length === 7 && ((parseInt(dateExp.slice(-2)) < this.currentYear) || (parseInt(dateExp.substring(0, 2)) < this.currentMonth && parseInt(dateExp.slice(-2)) === this.currentYear))) {
                        return false;
                    }
                    return true;
                }).withMessage('Invalid Date Expiry.')
                .on(this);
            const result = await this.validator.validate();
            if (result.valid) {
                this.readyingForm = true;
                const response = await this.customerService.updateCheckoutCard(this.firstNameExpiry, this.lastNameExpiry, this.selectedCard, this.expiryDate);
                if (response) {
                    this.availableCards = [];
                    this.checkoutVaultedProfile = await this.customerService.getCheckoutVaultProfile();
                    if (this.checkoutVaultedProfile?.instruments?.length > 0) {
                        this.usingPreviousCard = true;
                        for (const paymentSource of this.checkoutVaultedProfile?.instruments || 0) {
                            paymentSource.expiryMonth.toString().length < 2 ? paymentSource.expiryMonth = '0' + paymentSource.expiryMonth.toString() : paymentSource.expiryMonth;
                            paymentSource.expiryYear = paymentSource.expiryYear.toString().substring(2);
                            this.availableCards.push(paymentSource);
                        }
                        if (this.availableCards?.length > 0) {
                            const checkoutVaultLastUsedCard = await this.customerService.getCheckoutVaultLastUsedCard();
                            if (checkoutVaultLastUsedCard) {
                                this.selectedCard = this.availableCards.find(c => checkoutVaultLastUsedCard.source?.last4 === c.last4 &&
                                    checkoutVaultLastUsedCard.source?.scheme?.toLowerCase() === c.scheme.toLowerCase());
                                if (!this.selectedCard) {
                                    this.selectedCard = this.availableCards[0];
                                }
                            } else {
                                this.selectedCard = this.availableCards[0];
                            }
                        }
                        this.setBillingForCard();
                        this.parent.shouldShowBlueLine = true;
                        this.parent.summaryButtonState = 'active';
                    } else {
                        this.billing.zip = this.customer?.zip;
                        this.billing.street = this.customer?.address;
                        this.billing.country = this.customer?.country?.toUpperCase();
                        this.billing.state = this.customer?.state;
                        this.billing.city = this.customer?.city;
                    }
                    this.updatingCardExpiry = false;
                    this.toastService.showToast('Success!', 'Credit card details updated.', 'success');
                }
            } else {
                this.showGreenCheckMarkFirstNameExpiry = result.results.filter(r => r.propertyName === 'firstNameExpiry').every(r => r.valid);
                this.showErrorCheckMarkFirstNameExpiry = !this.showGreenCheckMarkFirstNameExpiry;
                this.showGreenCheckMarkLastNameExpiry = result.results.filter(r => r.propertyName === 'lastNameExpiry').every(r => r.valid);
                this.showErrorCheckMarkLastNameExpiry = !this.showGreenCheckMarkLastNameExpiry;
                this.showGreenCheckMarkDateExpiry = result.results.filter(r => r.propertyName === 'expiryDate').every(r => r.valid);
                this.showErrorCheckMarkDateExpiry = !this.showGreenCheckMarkDateExpiry;
                this.toastService.showToast('Error', 'Some required information is missing or incorrect. Please correct the address fields and try again.', 'error');
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.readyingForm = false;
        }
    }

    async checkFirstNameExpiryOnKeyPress() {
        ValidationRules.ensure('firstNameExpiry').required().on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkFirstNameExpiry = rules.valid;
        this.showErrorCheckMarkFirstNameExpiry = !rules.valid;
    }

    async checkLastNameExpiryOnKeyPress() {
        ValidationRules.ensure('lastNameExpiry').required().on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkLastNameExpiry = rules.valid;
        this.showErrorCheckMarkLastNameExpiry = !rules.valid;
    }

    async checkExpiryDateOnKeyPress(event) {
        ValidationRules
            .ensure('expiryDate').required().matches(/^(0[1-9]|1[0-2]) \/ ([0-9]{2})$/)
            .satisfies((dateExp) => {
                if (dateExp?.length === 7 && ((parseInt(dateExp.slice(-2)) < this.currentYear) || (parseInt(dateExp.substring(0, 2)) < this.currentMonth && parseInt(dateExp.slice(-2)) === this.currentYear))) {
                    return false;
                }
                return true;
            }).withMessage('Invalid Date Expiry.')
            .on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkDateExpiry = rules.valid;
        this.showErrorCheckMarkDateExpiry = !rules.valid;
        if (this.cardExpiryDate.value.length === 2 && !(event.keyCode === 46 || event.keyCode === 8)) {
            this.cardExpiryDate.value += ' / ';
        } else if (this.cardExpiryDate.value.length === 3) {
            this.replaceValue = this.cardExpiryDate.value.slice(-1);
            this.cardExpiryDate.value = this.cardExpiryDate.value.substring(0, 2);
            this.cardExpiryDate.value += ' / ';
            this.cardExpiryDate.value += this.replaceValue;
        } else if ((this.cardExpiryDate.value.length === 5 || this.cardExpiryDate.value.length === 4) && (event.keyCode === 46 || event.keyCode === 8)) {
            this.cardExpiryDate.value = this.cardExpiryDate.value.slice(0, 2);
        }
    }

    async handleCheckoutFraudDetection() {
        const risk = window.Risk.init(checkoutPublicKey());
        return await risk.publishRiskData();
    }
}
