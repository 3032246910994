/* eslint-disable no-extend-native */

interface StringAndNumberExtensions {
    toFloat(): number;
    toInt(): number;
}

declare global {
    interface String extends StringAndNumberExtensions {
        isProfane(): boolean;
        isFile(noRoute?: boolean): boolean;
        hasLetters(): boolean;
        toPascal(): string;
        removeSelectorSymbol(): string;
        toCamelCase(): string;
        toRoute(): string;
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface Number extends StringAndNumberExtensions {}
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface Object extends StringAndNumberExtensions {
        enumName(value): string;
        setProp(str: string, value: unknown): object;
        isEmpty(): boolean;
        mapper(callback): object;
    }
}

export class TypeExtensions {
    implement(helper) {
        // For Array and String
        const excludes = new BuiltProperty('excludes', function(val) { return !this.includes(val); });

        // For String And Number
        const parseFunctions = [
            new BuiltProperty('toFloat', function() { return parseFloat(this); }),
            new BuiltProperty('toInt', function() { return parseInt(this); })
        ];

        const methodExtensions = [
            new DsPrototype(
                String.prototype,
                [
                    excludes,
                    ...parseFunctions,
                    new BuiltProperty('isProfane', function() { return helper.isProfane(this); }),
                    new BuiltProperty('hasLetters', function() { return /[a-zA-Z]/g.test(this); }),
                    new BuiltProperty('isFile', function(noRoute = false) { return helper.isFile(this, noRoute); }),
                    new BuiltProperty('toPascal', function() { return helper.toPascal(this); }),
                    new BuiltProperty('removeSelectorSymbol', function() { return helper.removeSelectorSymbol(this); }),
                    new BuiltProperty('toCamelCase', function() { return helper.camelize(this); }),
                    new BuiltProperty('toRoute', function() { return helper.toRoute(this); })
                ]
            ),
            new DsPrototype(
                Array.prototype,
                [

                ]
            ),
            new DsPrototype(
                Number.prototype,
                [
                    ...parseFunctions
                ]
            ),
            new DsPrototype(
                Object.prototype,
                [
                    new BuiltProperty('enumName', function(value) { return helper.getEnumName(this, value); }),
                    new BuiltProperty('setProp', function(str, value) {
                        this[str] = value;
                        return this;
                    }),
                    new BuiltProperty('isEmpty', function() { return this && helper.isObjectEmpty(this); }),
                    new BuiltProperty('mapper', function(callback) { return helper.objectMap(this, callback);})
                ]
            )
        ];

        methodExtensions.forEach(proto => {
            proto.props.forEach(prop => {
                this.buildDefault(proto.type, prop.name, prop.callback);
            });
        });
    }

    buildDefault(type, name, callback) {
        Object.defineProperty(type, name, {
            value: callback,
            writable: true,
            configurable: true
        });
    }
}

class DsPrototype {
    type;
    props;
    constructor(type, props) {
        this.type = type;
        this.props = props;
    }
}

class BuiltProperty {
    name;
    callback;
    constructor(name, callback) {
        this.name = name;
        this.callback = callback;
    }
}
